@import "custom-variables";
// Custom Style file only for customer to use

.about-us {
    margin-top: 7%;
    @include max-screen(465) {
            margin-top: 20%;
        }
}

.adjust-margin{
    margin-top: 5%;
}

.flex-center-custom{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}
.text-nav{
    font-family: "Metropolis Semi Bold";
    line-height: 2.3;
    color: #fff;
    font-size: 90%;
    text-transform: uppercase;
    cursor: pointer;
}
