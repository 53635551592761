.custom-container {
  @include min-screen(1440) {
    max-width: 1240px;
  }
}
.path-img {
  position: absolute;
  left: 0;
  bottom: -71px;
  z-index: -1;
}
.icon-feature{
  top: 0px;
  position: relative;
  left: 48%;
  @include max-screen(475) {
      left: 45%;
    }
 
}

.icon-feature-2 {
  top: -120px;
  position: relative;
  left: 45%;
  width: 3.4%;
}

.icon-about{
  width: 15%;
}

.adjust-top{
  padding-top: 30px !important;
}

.prj-icon{
  padding: 13px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.text-prj{
  font-size: 13px;
  margin-left: 10px;
}
.img-about{
  width: 100%;
  border-radius: 5px;
}
