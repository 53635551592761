.hero-slide-content {
  @include max-screen(768, 1199) {
    padding-top: 50px;
  }
}

.hero-slider {
  position: relative;
  padding-top: 160px;
  overflow: hidden;
  
  @include min-screen(1200) {
    display: flex;
    align-items: center;
    height: 600px;
  }
  @include min-screen(1500) {
    display: flex;
    align-items: center;
    height: 800px;
  }

  .hero-btn{
    background-image: conic-gradient(from 1turn, #6ACF15, #209B44);
  }

  .title {
    animation-name: fadeInUp;
    animation-delay: 0.9s;
  }

  .delay1 {
    animation-name: fadeInUp;
    animation-delay: 1.5s;
  }
  .delay2 {
    animation-name: fadeInUp;
    animation-delay: 2s;
  }
  .hero-img {
    img {
      animation-name: fadeInLeft;
      animation-delay: 2.5s;
    }
  }
}

.hero-slide-content {
  .title {
    color: whitesmoke;
    font-weight: 700;
    font-size: 55px;
    @include min-screen(1500) {
      font-size: 60px;
    }
    @include max-screen(992, 1199) {
      font-size: 45px;
    }
    @include max-screen(767) {
      font-size: 35px;
      line-height: 1.4;
    }
    @include max-screen(480) {
      text-shadow: 1px 0px 1px #222020, 0px 1px 1px #120e0e, 2px 1px 1px #000, 1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #40d0ee, 4px 3px 1px #40d0ee, 3px 4px 1px #40d0ee;
      color: #fff;
      font-size: 30px;
      -webkit-text-stroke: 1px black;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
    @include max-screen(576, 767) {
      font-size: 45px;
    }
    line-height: 1.2;
    margin-bottom: 3.75rem;
  }
}

.hero-img {
  @include min-screen(1200) {
    position: absolute;
    bottom: 0px;
    right: 0;
  }
  max-width: 904px;
  @include max-screen(1200, 1499) {
    max-width: 700px;
  }

  @include max-screen(1500, 1800) {
    max-width: 48%;
  }
}

.negative-margin {
  @include min-screen(1200) {
    margin-top: 100px;
  }
}

.hero-shape1 {
  // background-image: url("https://dev.duniadalamdigital.com/duit-dev/images/slider/shape/background-banner.png");
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  @include min-screen(1500) {
    max-width: 1007px;
  }
  @include min-screen(1200) {
    max-width: 100%;
  }
  @include max-screen(465) {
    .hero-shape1-image{
      height: 100vh !important;
      object-fit: cover !important;
      object-position: 90% !important;
    }
  }
}
.hero-shape2 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  max-width: 878px;

  @include max-screen(1500, 1800) {
    max-width: 735px;
  }

  @include max-screen(1499) {
    max-width: 735px;
  }

  @include max-screen(1199) {
    display: none;
  }

  @include max-screen(1199) {
    top: auto;
    bottom: 0;
  }
}
.btn-blue {
  background: linear-gradient(90deg, rgba(13, 95, 157, 1) 5%, rgba(47, 87, 150, 1) 11%, rgba(24, 24, 145, 1) 58%);
  font-size: 16px !important;
  max-width: 390px !important;
  padding: 10px !important;
  border-radius: 50px !important;
  color: #c3c1bf !important;
  & :hover {
      color: #b12893 !important;
    }
}

