.testimonial-section {
  @include max-screen(992, 1199) {
    margin-bottom: 70px;
  }
}

.happy-customer {
  margin-bottom: 2.15rem;
  font-weight: 400;
  font-size: 24px;
  @include max-screen(767) {
    font-size: 20px;
  }
}

.project-customer {
  margin-bottom: 2.15rem;
  font-weight: 400;
  font-size: 18px;
  line-height: 180% !important;
  text-align: justify;

  @include max-screen(767) {
    font-size: 20px;
  }
}

.testimonial-text {
  font-size: 18px;
}
.quote {
  color: #151515;
  font-size: 50px;
  transform: rotate(180deg);
}

.avater-profile {
  margin-right: 1.25rem;
  img {
    border: 50%;
  }
}
.avater-info {
  p {
    font-family: "Metropolis Semi Bold";
    color: #636363;
    margin-bottom: 0;
    line-height: 1;
    font-size: 18px;
  }
  span {
    font-family: "Metropolis Light";
    color: #636363;
    font-weight: 300;
    font-size: 15px;
  }
}

// testimonial-carousel navigation style

.testimonial-carousel {
  .swiper-button-prev,
  .swiper-button-next {
    // color: $primary;
    color: white;
  }
  .swiper-button-prev {
    width: auto;
    left: 0;
    margin-left: 2% !important;
    background-color: #F7547F;

    .icofont-rounded-left {
      font-size: 30px;
    }
  }
  .swiper-button-next {
    width: auto;
    margin-right: 2% !important;
    background-color: #F7547F;
    
    .icofont-rounded-right {
      font-size: 30px;
    }
  }
  .swiper-pagination {
    width: 100%;
    margin-top: -5%;

    .swiper-pagination-bullet {
      background-color: #fff;
      width: 10px;
      height: 10px;
      opacity: 1;
      border-radius: 50%;
      margin: 0 5px;
    }

    .swiper-pagination-bullet-active {
      background-color: #F7547F;
    }
  }
}

.testimonial-carousel {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
    
    // width: 100%;
    // left: 0;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // align-content: center;
    // flex-wrap: nowrap;
    // flex-direction: row;
  }
}

.tetimonial-shape {
  position: absolute;
  top: 5%;
  left: 0;
  @include min-screen(992) {
    top: 50%;
    transform: translateY(-50%);
  }
  z-index: -1;
}
